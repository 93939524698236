import React from 'react'
import './Reasons.css'
import b1 from '../../assets/b1.png'
import b2 from '../../assets/b2.png'
import b3 from '../../assets/b3.png'
import b4 from '../../assets/b4.png'
import b5 from '../../assets/b5.png'
import check from '../../assets/check_circle.png'

import Nike from '../../assets/nike.png'
import Adidas from '../../assets/adidas.png'
import NB from '../../assets/nb.png'

export default function Reasons() {
  return (
    <section className="reason_container" id='reasons'>
      {/* Left Handside */}
      <div className="left_side_container">
        <img src={b4} alt="" />
        <img src={b3} alt="" />
        <img src={b1} alt="" />
        <img src={b2} alt="" />
        <img src={b5} alt="" />
      </div>

      {/* Right Handside */}
      <div className="right_side_container">
        <span>Some Reasons</span>
        <div>
          <span className="stroke_text">Why</span>
          <span> Choose Us?</span>
        </div>

        <div className="right_side_detials">
          <div>
            <img src={check} alt="" />
            <span>OVER 140 PROFESSIONAL COACHES</span>
          </div>
          <div>
            <img src={check} alt="" />
            <span>TRAIN FASTER AND SMATTER THAN BEFORE</span>
          </div>
          <div>
            <img src={check} alt="" />
            <span>1 FREE PROGRAM FOR NEW FELLOW</span>
          </div>
          <div>
            <img src={check} alt="" />
            <span>CONSISTENT ASSOCIATES </span>
          </div>
        </div>
        <span>Our <span className=''>Partners</span></span>
        <div className="our_partners">
          <img src={Adidas} alt="" />
          <img src={Nike} alt="" />
          <img src={NB} alt="" />
        </div>
      </div>
    </section>
  );
}
