import React, { useState } from 'react'
import { MdArrowLeft, MdArrowRight } from 'react-icons/md';
import './Testimonials.css'

import {testimonialsData} from '../../data/testimonialsData'

export default function Testimonials() {
    const [selected, setSelected] = useState(0);
    const tLength = testimonialsData.length;


  return (
    <section className="testimonials">
      {/* Left Side */}
      <div className="left_side">
        <span>Testimonials</span>
        <span className="stroke_text">What they</span>
        <span>say about us</span>
        <span>{ testimonialsData[selected].review}</span>
        <span>
            <span style={{color: 'var(--orange)'}}>{testimonialsData[selected].name} - {testimonialsData[selected].status}</span>
        </span>
      </div>
      {/* Right Side */}
      <div className="right_side">
        <div></div>
        <div></div>
        <img src={testimonialsData[selected].image} alt="" />
        <div className="arrow">
            <MdArrowLeft onClick={()=> {
                selected===0 ? setSelected(tLength - 1) : setSelected((prev) => prev - 1)
            }} className='inner_arrow' /> 
            <MdArrowRight onClick={()=> {
                selected === tLength - 1 ? setSelected(0) : setSelected((prev) => prev + 1);
            }} className='inner_arrow' />
        </div>
      </div>
    </section>
  );
}
