import React from 'react'
import './Footer.css'

import Twitter from '../../assets/twitter.png'
import Instangram from '../../assets/instagram.png'
import Linkedin from '../../assets/linkedin.png'
import Logo from '../../assets/Logo.png'

export default function Footer() {
  return (
    <section className="footer_container">
      <hr />
      <div className="footer">
        <div className="social_links">
          <img src={Twitter} alt="" />
          <img src={Linkedin} alt="" />
          <img src={Instangram} alt="" />
        </div>
        <div className="app_logo">
          <img src={Logo} alt="" />
        </div>
        <span className='brand'>Copywrite &copy; 2023 DEVOLAH</span>
      </div>
      <div className="blur footer_blur_1"></div>
      <div className="blur footer_blur_2"></div>
    </section>
  );
}
