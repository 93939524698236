import React from 'react'
import './Plans.css'
import Check from '../../assets/check2.png'
import {plansData} from '../../data/plansData'
import { MdArrowCircleRight } from 'react-icons/md'

export default function Plans() {
  return (
    <section className="plans_container" id='plans'>
      <div className="blur plans_blur_1"></div>
      <div className="blur plans_blur_2"></div>
      <div className="programs_header">
        <span className="stroke_text">READY TO START </span>
        <span>YOUR JOURNEY NOW</span>
        <span className="stroke_text">WITH US</span>
      </div>

      {/* Plans Card */}
      <div className="plans_card">
        {plansData.map((plan, i) => (
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>#{plan.price}</span>

            <div className="features">
              {plan.features.map((feature, i) => (
                <div className="feature">
                  <img src={Check} alt="" />
                  <span key={i}>{feature}</span>
                </div>
              ))}
              <div className='see_more'>
                <span>See more benefits </span>
                <MdArrowCircleRight/>
              </div>
              <button className="btn">Join Now</button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
