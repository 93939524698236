import React, { useRef } from 'react'
import './Join.css'

import emailjs from '@emailjs/browser';

export default function Join() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_37tuc7o','template_lxmlgb5', form.current, 'Cu4mkPPwgcXwsYSf7').then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    }

  return (
    <section className="join_container">
        {/* Left Side */}
        <div className="join_left_side">
            <div>
                <span className='stroke_text'><span className='read_text'>READY</span> TO </span>
                <span>LEVEL UP </span>
            </div>
            <div>
                <span>YOUR BODY </span>
                <span className='stroke_text'>WITH US?</span>
            </div>
        </div>
        {/* Right Side */}
        <div className="join_right_side">
           <form ref={form} onSubmit={sendEmail} className="email_container">
            <input type="email" name='user_email' placeholder='Enter your email address' />
            <button type='submit' className='btn btn_join'>Submit</button>
           </form>
        </div>
    </section>
  )
}
