import React from 'react'
import Header from '../header/Header'
import Heart from '../../assets/heart.png'
import HeroImage from '../../assets/hero.png'
import Calories from '../../assets/calories.png'
import CountUp from 'react-countup'
import {motion} from 'framer-motion'
import './Hero.css'

export default function Hero() {

  const transition = {type: 'spring', duration: 3}
  const mobile = window.innerWidth <= 768 ? true : false;
  return (
    <section className="hero" id="home">
      <div className="blur hero_blur"></div>
      <div className="left_h">
        <Header />

        <div className="the_best_ads">
          <motion.div
            initial={{ left: mobile ? "178px" : "230px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>Top fitness club of all times!</span>
        </div>
        {/* Hero Heading */}
        <div className="hero_text">
          <div>
            <span className="stroke_text">Build </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>
          <div>
            <span>
              Let's help you put your build in best and look more attractive
              through your lifetime!
            </span>
          </div>
        </div>

        {/* Figures */}

        <div className="figures">
          <div>
            <span>
             <CountUp start={100} end={140} prefix='+' />
            </span>{" "}
            <span>Expert Coaches</span>
          </div>
          <div>
            <span>
              <CountUp start={1000} end={1500} prefix='+' />
            </span>{" "}
            <span>Members Joined</span>
          </div>
          <div>
            <span>
               <CountUp end={150} prefix='+' />
            </span>{" "}
            <span>Fitness Program</span>
          </div>
        </div>

        {/* Hero Buttons */}
        <div className="hero_buttons">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>

      <div className="right_h">
        <button className="btn">Join Now</button>

        <motion.div
          initial={{ right: "-2rem" }}
          whileInView={{ left: "8rem" }}
          transition={{ ...transition, type: "tween" }}
          className="heart_rate"
        >
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>

        {/* Hero Image */}
        <img src={HeroImage} alt="" className="hero_image" />
        {/* <img src={HeroImageBack} alt="" className="hero_image" /> */}

        {/* Calories */}
        <motion.div
          initial={{right: "37rem"}}
          whileInView={{right: "27rem"}}
          transition={{...transition, type: "tween"}}
          className="calories"
        >
          <img src={Calories} alt="" />
          <div>
            <span>Calories Burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
