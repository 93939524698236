import React, { useState } from "react";
import "./Header.css";
import Logo from "../../assets/Logo.png";
import { MdMenu } from "react-icons/md";
import { Link } from "react-scroll";

export default function Header() {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);
  return (
    <section className="header">
      <img src={Logo} alt="" className="logo" />
      {menuOpened === false && mobile === true ? (
        <div style={{ background: "var(--appColor)", padding: "0.3rem" }}>
          <MdMenu
            style={{ fill: "orange", fontSize: "1.5rem" }}
            onClick={() => setMenuOpened(true)}
          />
        </div>
      ) : (
        <ul className="header_menu">
          <li>
            <Link
              to="home"
              activeClass="active"
              spy={true}
              smooth={true}
              onClick={() => setMenuOpened(false)}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="programs"
              spy={true}
              smooth={true}
              onClick={() => setMenuOpened(false)}
            >
              Programs
            </Link>{" "}
          </li>
          <li>
            <Link
              to="reasons"
              spy={true}
              smooth={true}
              onClick={() => setMenuOpened(false)}
            >
              Why Us
            </Link>{" "}
          </li>
          <li> <Link to="plans" span={true} smooth={true} onClick={() => setMenuOpened(false)}>Plans</Link></li>
          <li>
            <Link
              to="testimonials"
              spy={true}
              smooth={true}
              onClick={() => setMenuOpened(false)}
            >
              Testimonials
            </Link>
          </li>
        </ul>
      )}
    </section>
  );
}
