
export const ProgramData = [
  {
    image: "",
    heading: "Strength Training",
    detials: "In this program, you are trained to improve your strength through many exercises.",
  },
  {
    image: "",
    heading: "Cardio Training",
    detials: "In this program, you atre trained to do sequential moves in range of 20 until 30 minutes.",
  },
  {
    image: "",
    heading: "Fat Burning",
    detials: "This program is suitable for who wants to get rid of fat and lose weight.",
  },
  {
    image: "",
    heading: "Health Fitness",
    detials: "This programs is designed for thse who exercies only for their body fitness not body building."
  },
];
