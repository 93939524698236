import user1 from '../assets/user1.jpg'
import user2 from '../assets/user2.jpg'
import user3 from '../assets/user3.jpg'

export const testimonialsData = [
    {
        image: user1,
        review: "Click Insert and then choose the elements you want from the different galleries. Themes and styles also help keep your document coordinated.",
        name: "John Doe",
        status: "Customer"
    },
    {
        image: user2,
        review: "When you click Design and choose a new Theme, the pictures, charts, and SmartArt graphics change to match your new theme.",
        name: "Mary Clark",
        status: "Member"
    },
    {
        image: user3,
        review: "When you apply styles, your headings change to match the new theme. Save time in Word with new buttons that show up where you need them.",
        name: "Musa Ahmad",
        status: "Member"
    },
]