import React from 'react'
import './Programs.css'
import { ProgramData } from '../../data/ProgramsData';
import {MdArrowCircleRight} from 'react-icons/md'

export default function Programs() {
  return (
    <section className="programs" id="programs">
      {/* Header */}
      <div className="programs_header">
        <span className="stroke_text">Explore our</span>
        <span>Programs</span>
        <span className="stroke_text">to shape you</span>
      </div>

      {/* Categories */}
      <div className="program_categories">
        {ProgramData.map((program) => (
          <div className="category">
            <span>{program.image}</span>
            <span>{program.heading}</span>
            <span>{program.detials}</span>

            <div className="join_now">
              <div>
                <span>Join Now</span>
                  <MdArrowCircleRight />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
