export const plansData = [
    {
        icon: "",
        name: "BASIC PLAN",
        price: "5000",
        features : [
            "2 hours of excercises",
            "Free consultation to coaches",
            "Access to the Comunity"
        ],
    },
    {
        icon: "",
        name: "PREMIUM PLAN",
        price: "8000",
        features : [
            "2 hours of excercises",
            "Free consultation to coaches",
            "Access to minibar"
        ],
    },
    {
        icon: "",
        name: "PRO PLAN",
        price: "10000",
        features : [
            "8 hours of excercises",
            "Consultation of Private coaches",
            "Free Fitness Merchandises"
        ],
    },
]